import { YES_NO_NOT_ENTERED, NUMBER_INPUT_POSITIVE } from '@/forms/schemas/generators'
import { actions } from './financial'

export const FINANCIAL_OTHERS_INPUTS = [
  {
    name: 'price_per_square_meter',
    label: 'Prijs per vierkante meter',
    placeholder: 'Prijs per vierkante meter',
    outerClass: 'tw-m-0',
    ...NUMBER_INPUT_POSITIVE
  },
  {
    name: 'ground_lease',
    label: 'Erfpacht',
    placeholder: 'Erfpacht',
    outerClass: 'tw-m-0',
    ...NUMBER_INPUT_POSITIVE
  },
  {
    name: 'ground_lease_annual_cost',
    label: 'Erfpacht jaarlijkse kostprijs',
    placeholder: 'Erfpacht jaarlijkse kostprijs',
    outerClass: 'tw-m-0',
    ...NUMBER_INPUT_POSITIVE
  },
  {
    name: 'property_tax',
    label: 'Onroerende voorheffing',
    placeholder: 'Onroerende voorheffing',
    outerClass: 'tw-m-0',
    ...NUMBER_INPUT_POSITIVE
  },
  {
    name: 'property_tax_year',
    label: 'Jaartal onroerende voorheffing',
    placeholder: 'YYYY',
    outerClass: 'tw-m-0',
    ...NUMBER_INPUT_POSITIVE
  },
  {
    name: 'easement',
    label: 'Erfdienstbaarheid',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    type: 'translatable',
    name: 'easement_description',
    label: 'Informatie erfdienstbaarheid',
    outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
  }
]

const schema = [
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [...FINANCIAL_OTHERS_INPUTS]
  }
]

export default {
  heading: 'Andere financiële parameters (te huur)',
  schema,
  actions
}
